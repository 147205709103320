@font-face {
  font-family: 'Product Sans';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/Product-Sans-Regular.ttf');
}
@font-face {
  font-family: 'Product Sans';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/Product-Sans-Bold.ttf');
}
@font-face {
  font-family: 'Product Sans';
  font-weight: normal;
  font-style: italic;
  src: url('../fonts/Product-Sans-Italic.ttf');
}
@font-face {
  font-family: 'Product Sans';
  font-weight: bold;
  font-style: italic;
  src: url('../fonts/Product-Sans-Bold-Italic.ttf');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Product Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
div {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}